import { menuList } from "../../../router/menu";
import { mapState } from "vuex";

// 一张默认头像的 base64 格式
const defaultAvatar = "data:img/jpg;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAMAAAAM7l6QAAAAqFBMVEUAAADOlE3ZtG3NlU3OlE3OlU7OlE3Nk0zOlU/NlE3Pj1DNk03///7//v3////////////////////89/P++/n79e79/Pj8+PX9/fnNlE3////9/Pr37uPu3MXmyaX8+vbq1LfYrXbXqXDQm1rPmVbOllD79vD27ODy49Hw3snt2cDp0LHfu47euInasHvXqnLSoGH16tz06dvny6njw5ziw5vbsn/VpmwjCafAAAAAGXRSTlMA6gWE+MWSgFRMEKjp+OuAVEwQ6cXFkpKEcJPT3gAAARlJREFUKM91ktdygzAQRRdRBbimCowdG3C305P//7MYDXtXzozPAzB7ZtEWEVA6Cr0g8MJIK/pPGnsGeHF6bRMrBS9xrY848MVmBgjZ7Vw3PzE3sOenUtWqWpebH6mvqz82zLGwLE4ciIkUkn/LovdvnK5II/mlYOYc0hRBb6C3HIoohC6h1xwKCUc3hdDy4RSgK0dzbYHo2tE1tAylgt1hME5pS+gVSpPGpPGDkca0EbbWVgbobqjggJlhqM5KmpnV762sRBbaHi/W8nFqeKF8HZbVohDK3bm7DrhM3338Fe19GV+uIv93b/b91ywj4PO2PtH8MzmMc/752b7zMV0xHQ1tz/X88hyOphCMmjw93A0G+f3jRCH4BzBnRjBBI4lvAAAAAElFTkSuQmCC";

export default {
	name: "Layout",

	data: () => ({
		// menu: menuList,
		menu: [menuList[0]],
		isCollapse: true,
		defaultAcitve: "", // 当前激活的导航
		expandMenu: null, // 当前展开的二级菜单
		defaultAvatar,    // 默认头像
        breadList: [],
        sysIconMap: '',
        sysName: '',
        mainPath: '', // require('./images/logo.png')
	}),

	computed: {
		// ...mapState(["breadList"]), // 面包屑
		asideWidth() {
			return (this.isCollapse ? 64 : 200) + "px"
		}
	},

	created() {
		this.generateMenu();

		// 获取全局配置信息：区域、业务领域等
		this.fetchConfigs();

		// 设置当前展开的二级菜单
		if (location.pathname) {
			this.expandMenu = "/" + location.pathname.split("/")[1]; // 第一个为空字符串，因为开头为/
		}

        this.sysIconMap = JSON.parse(localStorage.getItem("sysIconMap"))
        if (this.sysIconMap) {
            if (this.sysIconMap.mainPath) {
                this.mainPath = this.sysIconMap.realMainPath
            } else {
                this.mainPath = require('./images/logo2.png')
            }
            if (this.sysIconMap.name) {
                this.sysName = this.sysIconMap.name
            } else {
                this.sysName = '海居邦'
            }

        }

	},

	methods: {
		// 根据用户的权限列表生成目录
		generateMenu() {
			const { pathList } = this.userInfo;
			/**
			* 用户url权限数据，根据升序排序
			* 再把 子数据 父级的 children 中
			*/
			const navUrlList = [...pathList];
			navUrlList.sort((prev, next) => {
				if (prev.type != next.type) {
					return prev.type - next.type;
				} else {
					return prev.ord - next.ord;
				}
			});
			
			let i = navUrlList.length - 1;
			while (i >= 0 && navUrlList[i].type > 0) {
				const curItem = navUrlList[i];
				for (let j = i - 1; j >= 0; j--) {
					if (curItem.parent_id == navUrlList[j].id) {
						if (!(navUrlList[j].children instanceof Array)) {
							navUrlList[j].children = [];
						}
						// 此处 push 会使排序颠倒
						navUrlList[j].children.unshift(curItem);
						navUrlList.length = i;
						break;
					}
				}
				i--;
			}
			this.menu.splice(1, this.menu.length, ...navUrlList.filter(item => 
				!(item.path === "/home" && item.children))); // 排除掉主页那个模块，因为那个模块的结构和其他模块的结构不一致，我们用固定this.menu[0]的代替
			this.defaultAcitve = this.extractTop2Path();
		},

		// 获取常用的配置信息，在 store 中统一管理
		fetchConfigs() {

			// 获取岗位配置
			this.$axios({
				url: "/api/post/queryPostAll",
				method: "post"
			}).then(res => {
				if (res.code === 2000) {
					this.$store.commit("setPostCategories", res.data.postAll)
				}
			})

		},

		// 切换导航折叠状态
		toggleCollapse() {
			this.isCollapse = !this.isCollapse
		},

		// 二级菜单展开的事件处理函数
		handleSubMenuOpen(path) {
			if (this.expandMenu && this.expandMenu !== path) {
				this.$refs.menu.close(this.expandMenu);
			}
			this.expandMenu = path;
		},

		// 点击头像
		handleAvatarClick() {
			if (this.$route.path !== "/my/updatePwd")
				this.$router.push("/my/updatePwd");
		},

		// 点击退出
		handleSignOut() {

			this.$confirm('确定要退出登录吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$store.commit("clearUserInfo");
				// // 清空用户信息
				// localStorage.removeItem("token");
				// localStorage.removeItem("userInfo");
				// location.reload();
			});

		},

		// 提取当前匹配的路由的前两层："/basic/banner/edit/2" -> "/basic/banner"
		// 用于设置目录的当前高亮项
		extractTop2Path(path) {
			path = path || this.$route.fullPath;
			return path.match(/^(\/\w*){1,2}/)[0]
		}

	},

	watch: {
		"$route": {
			immediate: true,
			handler: function (val) {
				this.defaultAcitve = this.extractTop2Path(val.fullPath);

				// 更新面包屑
				const { pathList } = this.userInfo;
				if (val.fullPath == "/home")
					return this.breadList = [{
						name: "首页",
						to: ""
					}];

				let breadList = [],
					currPath  = "",
					restPath  = val.fullPath.split("/").filter(
						dName=>(Boolean(dName)&&!(/^\d+(\?.*)?$/.test(dName)))),
					flag      = true;

				while (restPath.length) {
					const dName = restPath.shift()
					currPath += ("/" + dName);
					if (dName == "add") {
						breadList.push({
							name: "新增",
							to: ""
						})
					}
					else if (dName == "edit") {
						breadList.push({
							name: "编辑",
							to: ""
						})
					}
					else {
						const item = pathList.find(it => it.webpath == currPath);
						// console.log(dName, val, item)
						if (item) breadList.push({
							name: item.menuname,
							to: flag || (currPath == val.fullPath) ? "" : currPath
						})
						else {
							let item = val.matched.find(it => it.path == currPath);
							item = item || val;
							breadList.push({
								name: item.meta.title,
								to: flag || (currPath == item.fullPath || item.path) ? "" : currPath
							});
							// break;
						}
						flag = false;
					}
				}
				this.breadList = breadList;
			}
		}
	}
};
